import React from "react";

import { WorkspaceAccount } from "common/types";
import { LinkedInLogin } from "common/components/LinkedInLogin";

import { AccountAction as AccountActionType } from "../../types";
import ModifyLicense from "./ModifyLicense";
import DeleteAccount from "./DeleteAccount";
import RemoveCancellation from "./RemoveCancellation";

interface AccountActionsProps {
  account: WorkspaceAccount;
  action: AccountActionType;
  onSuccess: () => void;
  goBack: () => void;
}

export default function AccountActions({
  action,
  account,
  onSuccess,
  goBack,
}: AccountActionsProps) {
  if (action === "license") {
    return (
      <ModifyLicense
        type={account.license ? "unassign" : "assign"}
        accountFullname={account.full_name}
        accountId={account.id}
        goBack={goBack}
        onSuccess={onSuccess}
      />
    );
  }

  if (action === "delete") {
    return (
      <DeleteAccount
        accountFullname={account.full_name}
        accountId={account.id}
        goBack={goBack}
        onSuccess={onSuccess}
      />
    );
  }

  if (action === "reconnect") {
    return <LinkedInLogin account={account} onSuccess={onSuccess} />;
  }

  if (action === "reactivate") {
    return (
      <RemoveCancellation
        accountId={account.id}
        onSuccess={onSuccess}
        goBack={goBack}
      />
    );
  }

  return null;
}
