import checkAuth from "common/helpers/checkAuth";

const apiUrl = import.meta.env.VITE_API_URL;

export class HTTPError extends Error {
  code: number;

  type: string;

  serverMessage: string;

  data: string;

  constructor(message: string, code: number) {
    super(message);
    this.name = "HTTPError";
    this.code = code;
    this.type = null;
    this.serverMessage = null;
    this.data = null;
  }
}

function headers(token: string, jsonContent = false) {
  return {
    ...(token && { Authorization: `Bearer ${token}` }),
    ...(jsonContent && { "Content-Type": "application/json" }),
  };
}

function isJsonResponse(response: Response) {
  const contentType = response.headers.get("content-type");
  return contentType && contentType.indexOf("application/json") !== -1;
}

async function validateStatusCode(response: Response) {
  if (!response.ok) {
    // IF this message is ever changed, make sure to update sentry configuration, since it depends on the message
    // to ignore these errors
    const errorMessage = `Fetch error: Request to ${response.url} failed with status code: ${response.status}`;
    const error = new HTTPError(errorMessage, response.status);

    if (isJsonResponse(response)) {
      const jsonResponse = await response.json();
      const { error: serverErrorObject } = jsonResponse;

      // our 401 errors have this type
      if (
        response.status === 401 &&
        serverErrorObject.type === "UnauthorizedError"
      ) {
        // Catch promise rejection to avoid Sentry logging errors
        await checkAuth().catch(() => {});
      } else {
        error.serverMessage = serverErrorObject.message;
        error.type = serverErrorObject.type;
        error.data = serverErrorObject.data;
        throw error;
      }
    } else {
      throw error;
    }
  }
  return response;
}

async function validateContentType(response: Response) {
  if (!isJsonResponse(response)) {
    throw new Error(`Invalid Content Type: ${response.status}`);
  }
  return response;
}

async function parseResponse(response: Response) {
  if (isJsonResponse(response)) {
    return response.json();
  }
  return response.text();
}

/**
 * Perform a GET HTTP request.
 * @public
 * @param {String} path The request path.
 * @returns {Promise<Any>} The request response.
 */

export function get(path: string) {
  const token = localStorage.getItem("auth");

  return fetch(apiUrl ? `${apiUrl}${path}` : path, {
    method: "GET",
    headers: headers(token),
  })
    .then(validateStatusCode)
    .then(validateContentType)
    .then(parseResponse);
}

export function post(path: string, data?: object) {
  const token = localStorage.getItem("auth");
  return fetch(apiUrl ? `${apiUrl}${path}` : path, {
    method: "POST",
    body: data ? JSON.stringify(data) : null,
    headers: headers(token, true),
  })
    .then(validateStatusCode)
    .then(parseResponse);
}

export function put(path: string, data?: object) {
  const token = localStorage.getItem("auth");
  return fetch(apiUrl ? `${apiUrl}${path}` : path, {
    method: "PUT",
    body: data ? JSON.stringify(data) : null,
    headers: headers(token, true),
  })
    .then(validateStatusCode)
    .then(parseResponse);
}

export function patch(path: string, data: object) {
  const token = localStorage.getItem("auth");
  return fetch(apiUrl ? `${apiUrl}${path}` : path, {
    method: "PATCH",
    body: data ? JSON.stringify(data) : null,
    headers: headers(token, true),
  })
    .then(validateStatusCode)
    .then(parseResponse);
}

export function del(path: string) {
  const token = localStorage.getItem("auth");
  return fetch(apiUrl ? `${apiUrl}${path}` : path, {
    method: "DELETE",
    headers: headers(token),
  })
    .then(validateStatusCode)
    .then(parseResponse);
}
